import {
    MenuSheet,
    MobileMenu,
    MobileMenuButton,
} from "./mobileElements";
import {TopBrands} from "./topBrands";
import {initializeElement} from "./utils";

initializeElement(MobileMenuButton);

initializeElement(MobileMenu);
initializeElement(MenuSheet);
initializeElement(TopBrands);
